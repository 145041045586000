import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import logo from "../images/ikona.svg"
import {Navbar, Container, Nav} from 'react-bootstrap'




class Navigation extends React.Component {
    constructor(props) {
        super(props)
        this.state ={}
    }
    componentDidMount () {

        window.addEventListener('scroll', () => {
            let activeClass = 'normal';
            if(window.scrollY < 50){
                activeClass = '';
            }
            this.setState({ activeClass });
        });
    }
    componentWillUnmount() { window.removeEventListener('scroll', null); }
    render () {
        return (

            <Navbar collapseOnSelect expand="lg" bg="light" variant="dark" fixed="top" className={`${this.state.activeClass}`}>
                <Container>
                    <Link to="/" className="logo">
                        <Navbar.Brand>
                            <img
                                src={logo}
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                                alt="SmartSolution"
                            />
                            <span className="logo"><span className="font-weight-bold">{' Smart'}</span>{' Solution'}</span>
                        </Navbar.Brand>
                    </Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="#features">&nbsp;</Nav.Link>

                        </Nav>
                        <Nav>
                            <Link to="/" className="nav-link">Home</Link>
                            <Link to="/about" className="nav-link">About us</Link>
                            <Link to="/services" className="nav-link">Services</Link>
                            <Link to="/careers" className="nav-link">Careers</Link>
                            <Link to="/contact" className="nav-link">Contact</Link>


                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

export default Navigation


// const Navcomponent = ({ siteTitle }) => (


//   <Navbar collapseOnSelect expand="lg" bg="light" variant="dark" fixed="top" >
//     <Container>
//       <Link to="/">
//         <Navbar.Brand>
//             <img
//               src={logo}
//               width="30"
//               height="30"
//               className="d-inline-block align-top"
//               alt="SmartSolution"
//             />
//             <span className="logo"><span className="font-weight-bold">{' Smart'}</span>{' Solution'}</span>
//         </Navbar.Brand>
//       </Link>
//     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//     <Navbar.Collapse id="responsive-navbar-nav">
//       <Nav className="mr-auto">
//         <Nav.Link href="#features">&nbsp;</Nav.Link>

//       </Nav>
//       <Nav>
//         <Link to="/" className="nav-link">Home</Link>
//         <Link to="/about" className="nav-link">About</Link>
//         <Link to="/services" className="nav-link">Services</Link>
//         <Link to="/contact" className="nav-link">Contact</Link>

//         <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
//           <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
//           <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
//           <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
//           <NavDropdown.Divider />
//           <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
//         </NavDropdown>
//       </Nav>
//     </Navbar.Collapse>
//     </Container>
// </Navbar>
//  )

// Navcomponent.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Navcomponent.defaultProps = {
//   siteTitle: ``,
// }

// export default Navcomponent
