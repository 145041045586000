import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import BackgroundImg from "../images/video/indexheader.mp4"
import {Container, Nav, NavDropdown, Form, FormControl, Button} from 'react-bootstrap'
import logo from "../images/ikona.svg";



const Footer = () => (

    <footer className="footer bg-dark">
        <Container>
            <div className="row">
                <div className="col-12 col-md-5 mt-5">
                    <div className="d-flex align-items-center">
                        <div className="pb-1 pr-2">
                            <img
                                src={logo}
                                width="20"
                                height="20"
                                alt="SmartSolution"
                            />
                        </div>
                        <div>
                            <span className="logo text-light"><span className="font-weight-bold">{' Smart'}</span>{' Solution'}</span>
                        </div>
                    </div>
                    <p className="pl-4 pt-3 text-muted">Smart Solution Group LLC  is a registered company in the Republic of Kosovo which operates under the name "Smart Solution"</p>
                </div>
                <div className="col-12 col-md-5 mt-5">
                    <h4>How to reach us</h4>
                    <p className="d-flex"><div style={{ width: `65px` }} className="text-muted">E-mail:</div> <div>info@smartsolution-llc.com</div></p>
                    <p className="d-flex"><div style={{ width: `65px` }} className="text-muted">Phone:</div> <div>+383 49 88 99 88<br />+383 45 14 56 78<br />+44 7979 486 412</div></p>
                    <p className="d-flex"><div style={{ width: `65px` }} className="text-muted">Address:</div> <div>Dardania B9 - B3 nr 8, <br />10000, Prishtina, Kosovo. </div></p>

                </div>

                {/*<div className="col-12 col-md-2 d-flex align-items-center">
                    <a href="http://linkedin.com/company/smartsolutionllc">LinkedIn</a>
                </div>
                */}
            </div>
        </Container>
    </footer>
)


export default Footer