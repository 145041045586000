import { Link } from "gatsby"

import React from "react"
import Breadcrumb from 'react-bootstrap/Breadcrumb'


const ServicesBreadcrumb = () => (
    <Breadcrumb>
        <Breadcrumb.Item ><Link to="/services">Services</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to="/services/call-center">Call Center</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to="/services/it-solutions">IT Solutions</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to="/services/back-office">Back-office</Link></Breadcrumb.Item>
    </Breadcrumb>
)


export default ServicesBreadcrumb
