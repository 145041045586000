import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ServicesBreadcrumb from "../components/services-breadcrumb";

import {Container} from 'react-bootstrap'



const PageHeaderComponent = props => (

    <header
        className="mainhead">

        <div className="header-overlay">

            <Container>
                <div className="page-header-box">

                    {props.breadcrumb ? (
                        <ServicesBreadcrumb />
                    ) : (
                        <span></span>
                    )}
                    <div className="page-title">{props.name}</div>
                    {props.desc ? (
                        <div className="page-description">{props.desc}</div>
                    ) : (
                        <div className="page-description"></div>
                    ) }
                </div>
            </Container>
        </div>

    </header>
)

PageHeaderComponent.propTypes = {
    siteTitle: PropTypes.string,
}

PageHeaderComponent.defaultProps = {
    siteTitle: ``,
}

export default PageHeaderComponent
