import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PageHeaderComponent from "../../components/page-header";
import BgImage from "../../images/pages/call-center.jpg"
import { Container } from "react-bootstrap";

const CallCenterPage = () => (
    <Layout >
        <div style={{
            backgroundImage: `url(${BgImage})` ,
            backgroundPosition:`bottom center`,
            backgroundSize:`cover`
        }}>


            <PageHeaderComponent breadcrumb="services" name="Call Center" desc="We provide a full service call center solution for your business with our agents handling all points of contact. We work closely with you to ensure that your business culture and needs are fully represented in all areas during the onboarding process and setup. We ensure that the Agent representation you receive is what you truly need to help maximize your growth potential."/>
        </div>
        <SEO title="Call Center" />
        <section className="page-section">
            <Container>
                <div className="col-md-8 mb-5 text-large">
                    <h3>Telesales & Upsells</h3>
                    <p>We can help identify, engage and convert new leads with our vast experience in closing the deal with our
                        experienced team.</p>
                    <hr className="mt-5"/>
                </div>
                <div className="col-md-8 mb-5 text-large">
                    <h3>Market Research</h3>
                    <p>Gather valuable business intelligence effectively and efficiently with our team specializing in research and
                        information gathering.</p>
                    <hr className="mt-5"/>
                </div>
                <div className="col-md-8 mb-5 text-large">
                    <h3>Customer Retention</h3>
                    <p>Follow up with your clients and customers with targeted messaging and communication for a personalized experience
                        </p>
                    <hr className="mt-5"/>
                </div>
                <div className="col-md-8 mb-5 text-large">
                    <h3>Seminar & Event Registration</h3>
                    <p>We’ve specialized in reservation services for multiple industries and have established a proven model of success
                        for over 20 years.</p>
                    <hr className="mt-5"/>
                </div>
                <div className="col-md-8 mb-5 text-large">
                    <h3>Appointment Setting</h3>
                    <p>Gather valuable business intelligence effectively and efficiently with our team specializing in research and
                        information gathering.</p>
                    <hr className="mt-5"/>
                </div>
                <div className="col-md-8 mb-5 text-large">
                    <h3>Market Research</h3>
                    <p>We can integrate with your platform or use our system to set appointments, confirm dates and increase show rates.
                        </p>
                    <hr className="mt-5"/>
                </div>
                <div className="col-md-8 mb-5 text-large">
                    <h3>Scheduled Callbacks</h3>
                    <p>Never miss a call and opportunity to contact your customers with our unique feature allowing us to communicate at
                        their convenience.</p>
                    <hr className="mt-5"/>
                </div>
                <div className="col-md-8 mb-5 text-large">
                    <h3>Surveys & Feedbacks</h3>
                    <p>Obtain actionable feedback with our surveys able to provided to your callers immediately to gain insight into
                        satisfaction levels.</p>
                </div>
            </Container>
        </section>
    </Layout>
)

export default CallCenterPage
